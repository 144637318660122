import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { API_URL,apiSigningKey, SUICA_SERVER } from '../common/api';
import {dbPostResp } from '../model/dbPostResp';
import { catchError } from 'rxjs/operators';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { PageLoaderService } from './page-loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStoreService } from './localstore.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
declare const CryptoJSAesJson;

@Injectable({
  providedIn: 'root'
})
export class KioskApiService {

  constructor(
    private httpClient: HttpClient,
    private toastController: ToastController,
    private pageLoaderService: PageLoaderService,
    private router: Router,
    private route: ActivatedRoute,
    private store: LocalStoreService,
    private modalController: ModalController,
    private translate: TranslateService,
    public alertController: AlertController,
  ) { }

  apiReq(method: string, endPoint: string, payload?: any): Observable<any> {
    let user = this.store.get('user');
    const signingKey = apiSigningKey;
    const timestamp = moment().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    const body = payload ? JSON.stringify(payload) : '';
    const data = `${timestamp}|${body}`;
    const signature = CryptoJS.HmacSHA256(data, signingKey).toString(CryptoJS.enc.Hex);
    let options: any = {
    headers: new HttpHeaders({
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${user.token}`,
    // 'token': '75FC9C20269896C09FB07E47F09AAF864AFC961F3C6FDEFB200F43E3876FA884',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    'Accept-Language': this.store.getLanguageUuid(),
    'Accept-Language-Code': this.store.getLanguageCode(),
    'Reference': 'Kiosk',
    'X-Signature': signature,
    'X-Timestamp': timestamp,
    })
    }
    switch (method) {
    case 'get':
    return this.httpClient.get(API_URL + endPoint, options ).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    case 'post':
    return this.httpClient.post(API_URL + endPoint, payload,options).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    case 'put':
    return this.httpClient.put(API_URL + endPoint, payload).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    case 'patch':
    return this.httpClient.patch(API_URL + endPoint, payload).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    case 'delete':
    return this.httpClient.delete(API_URL + endPoint, payload).pipe(
      map(response => {
        if (typeof response == "string") {
          let password = apiSigningKey;
          const encryptedData = CryptoJSAesJson.decrypt(response, password);
          return encryptedData;
        } else {
          return response;
        }
      })
    );
    default:
    return null;
    }
    }



  restaurant_Operational():Observable<any>{
    let restaurantDetails = this.store.getRestaurant();
    let param = {
    "ref": "kiosk",
    "master_restaurant_uuid": restaurantDetails.master_restaurant_uuid
    }
    return this.apiReq('post', 'masterrestaurant/isrestaurantoperational', param)
  }

  Menu_items():Observable<any>{
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid");
    let user_information_uuid = localStorage.getItem("user_information_uuid");
    let payload = { "ref": "kiosk", "master_restaurant_uuid": master_restaurant_uuid, "user_information_uuid": user_information_uuid };
    // return this.apiReq('post', 'mastermenuitem/getcategorymenuitemsbyrestaurantuuid', payload)
    return this.apiReq('post', 'mastermenuitem/getpagermenuitemsbyrestaurantuuid', payload)
  }

  restaurant_Details():Observable<any>{
    const payload ={
      "ref": "kiosk",
      'master_restaurant_uuid': localStorage.getItem("master_restaurant_uuid"),
    }
    return this.apiReq('post', 'masterrestaurant/getbyuuid', payload);
   }

   kiosk_settings():Observable<any>{
    let countryData = this.store.getCountry();
    let countryUuid = countryData['master_country_uuid'];
   
    return this.apiReq('get', 'payment/kiosksetting?cid=' + countryUuid)
  }

  appsettings():Observable<any>{
    let countryData = this.store.getCountry();
    let countryUuid = countryData['master_country_uuid'];
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    return this.apiReq('get', 'payment/appsetting?cid=' + countryUuid +'&rid='+master_restaurant_uuid+'&type='+"kiosk")
  }


   app_settings():Observable<any>{
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    let data = {
      "master_restaurant_uuid": master_restaurant_uuid
    }
    return this.apiReq('post', 'masterrestaurant/getrestaurantsetting', data)
   }

   create_kiosk_order(data):Observable<any>{
    return this.apiReq('post', 'orderinfo/createkioskorder', data)
  }


  update_order(data):Observable<any>{
    return this.apiReq('post', 'orderinfo/updatekioskorderinfostatus', data)
   }

   cart_available():Observable<any>{
    let restaurantDetails = this.store.getRestaurant();
    let orderitem = this.store.getUserCart();
    let data = {
      master_restaurant_uuid: restaurantDetails.master_restaurant_uuid,
      orders: orderitem[0]
    }
   return  this.apiReq('post', 'orderinfo/isitemavailable', data)
   }

   item_detail(){
    let item_uuid = this.route.snapshot.queryParamMap.get('id');
    let data = {
      "ref":"kiosk",
      master_menu_item_uuid: item_uuid,
      user_information_uuid: "",
      restaurant_uuid: this.store.getRestaurantuuid('master_restaurant_uuid'),
    };
    return this.apiReq('post', 'mastermenuitem/getitemdetailbyitemuuid', data)
    
  }

}
