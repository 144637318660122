
import { Component } from '@angular/core';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { TranslateConfigService } from '../services/translate-config.service';
import { DOMAIN_URL, page_redirection_time, setIntervalTimeOut, telemetryTimeout, cart_time, menu_items_timer, images_display_timer } from '../common/api';
import { environment } from 'src/environments/environment';
import { AuditLogService } from '../services/audit-log.service';
import { KioskApiService } from '../services/kiosk-api.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  kiosk_font_color = "#61BE7B";
  params: any;
  restaurant_status: any;
  for_api_timer: any;
  maintain_timer: any;
  is_maintaince_mode_on = true;
  appSettings: any;
  shop: any;
  type = "0"
  menuItems = {};
  totalAmount: number;
  settings = {};
  orderType = "";
  currency = "¥";
  showDecimal = true;
  cartItems: [];
  cartItemCount: number;
  totalCartAmount: number = 0;
  payment: any;
  timer_start = true;
  menu_item: boolean;
  page_redirection_time_left = page_redirection_time;
  time_left_redirection_counter: any;
  is_restaurant_closed_status = "NO";
  time_left = 0;
  time_left_counter: any;
  obj_restaurantDetails;
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;
  app_settings;
  terminalid = this.store.get('terminalid');
  obj_is_restaurant_closed;
  ella_max_drinks;
  kiosk_banner_image=""
  menuItems_length:any;
  display_images:any;
  menuItems_timer:any;
  res_settings:any;
  one_pager_kiosk: any;
  cat_show: string;
  menuItems_withoutCat:any = []

  constructor(
    private router: Router,
    private kioskauthservice: KioskauthService,
    private store: LocalStoreService,
    private translateConfigService: TranslateConfigService,
    public alertController: AlertController,
    public auditlogServices: AuditLogService,
    private kiosk_apiService: KioskApiService
  ) {
    this.translateConfigService.getDefaultLanguage();
    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
    this.getAppSettings();
  }



  ngOnInit() {
    this.is_restaurant_closed();
    this.getRestaurantDetails();
    this.time_left = cart_time;
    clearInterval(this.time_left_counter);
    clearInterval(this.maintain_timer);

  }

  is_restaurant_closed() {
    this.obj_is_restaurant_closed = this.kiosk_apiService.restaurant_Operational()
      .subscribe(
        (res) => {
         
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.is_restaurant_closed_status = "NO";
            } else {
              this.is_restaurant_closed_status = "YES";
            }
          }
        },
      );
  }

  ellaMaxDrinks(){
    this.kiosk_apiService.restaurant_Operational()
      .subscribe(
        (res) => {
         
          if (res.statusCode == 200) {
            this.ella_max_drinks = res.ella_max_drinks
          }
        },
      );
  }
  
  ionViewWillEnter() {
    this.payment_printing_setting()
    this.ellaMaxDrinks()
    this.getMenuItems();
    this.menu_item = false;
    this.reset_time();
    this.restaurant_status = setInterval(
      () => {
        this.is_restaurant_closed();
      }
      , 10000);
    this.for_api_timer = setInterval(
      () => {
        this.getRestaurantDetails();
      }
      , 12000);
    this.clock_Timer();
    this.orderType = this.store.get('order_type');
    if (this.cartItemCount == 0) {
      clearInterval(this.time_left_counter);
    }
    this.maintain_timer = setInterval(
      () => {
        if (this.shop.master_restaurant_status == 'M_ON') {
          this.is_maintaince_mode_on = true;
        }
        else {
          this.is_maintaince_mode_on = false;
        }
      }
      , telemetryTimeout);
      // this.display_images =setInterval(()=>{
      //   this.getAppSettings();
      // },images_display_timer)
      this.menuItems_timer =setInterval(()=>{
        this.getMenuItems();
      },menu_items_timer)
      this.auditlogServices.audit_log("HOME");
      this.auditlogServices.logEvents("HOME")
  }

  getMenuItems() {
    this.kiosk_apiService
      .Menu_items()
      .subscribe((response) => {
        if (response.status === false) {
          this.kioskauthservice.onError(response.message);
        } else {
          if(this.one_pager_kiosk == false || this.one_pager_kiosk == "false" || this.one_pager_kiosk == null || this.one_pager_kiosk == undefined){
            if (response.final_response) {
              this.cat_show = "true";
              this.menu_item = true;
              this.menuItems = response.final_response;
              this.menuItems_length = Object.keys(this.menuItems).length
              
            } else {
              this.menu_item = true;
              this.cat_show = "true";
              this.menuItems = response.aaData;
              this.menuItems_length = Object.keys(this.menuItems).length
            }
          }else{
            if (response.final_response) {
              this.menu_item = true;
              this.cat_show = "false";
              this.menuItems_withoutCat = response.final_response;
              this.menuItems_length = Object.keys(this.menuItems).length
              
            } else {
              this.cat_show = "false";
              this.menu_item = true;
              this.menuItems_withoutCat = response.aaData;
              this.menuItems_length = Object.keys(this.menuItems).length
            }
          }
        }
      },
        error => {
        }
      );
  }


  async redirectOnDetailPage(item) {
    this.kiosk_apiService.restaurant_Operational()
      .subscribe(
        (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.redirectOnItemDetailPage(item);
            } else {
              this.kioskauthservice.isRestaurantOpenAlert();
              return false;
            }
          }
        },
        error => {
        }
      );
  }

  async redirectOnItemDetailPage(item) {
    if (item.is_food_category_time_available == false) {
      this.kioskauthservice.itemNotAvailableAlert();
      return false;
    } else {
          this.router.navigateByUrl(`product-detail?id=` + item.master_menu_item_uuid);
    }
  }

  getTotalQuantity(item) {
    return item[0].total_quantity;
  }

  async technical_error() {
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Oops!',
      message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
      buttons: [
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            let Autologin = this.store.get('autoLoginUrl')
            this.store.remove('sessionId')
            this.store.hardlogout()
            location.href = DOMAIN_URL + Autologin;
          }
        }
      ]
    });
    setTimeout(() => {   let Autologin = this.store.get('autoLoginUrl')
      this.store.remove('sessionId')
      this.store.hardlogout()
      location.href = DOMAIN_URL + Autologin;
      this.alertController.dismiss(); }, 5000);
    (await alert).present();
  }

  removeItemFromCart(index) {
    let cart = this.store.getUserCart();
    cart.splice(index, 1);
    this.store.setUserCart(cart);
    this.ionViewWillEnter();
    this.auditlogServices.audit_log("CART_ITEM_REMOVED");
    this.auditlogServices.logEvents("CART_ITEM_REMOVED")

  }

  editItem(index, itemUuid) {
    this.router.navigateByUrl('/product-detail?id=' + itemUuid + '&ci=' + index);
  }

  doRefresh(event) {
    this.kiosk_apiService.restaurant_Details()
      .subscribe((res) => {
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          event.target.complete();
          this.maintain_timer = setInterval(
            () => {
              if (this.shop.master_restaurant_status == 'M_ON') {
                this.is_maintaince_mode_on = true;
              }
              else {
                this.is_maintaince_mode_on = false;
              }
              this.is_maintaince_mode_on = false;
            }
            , telemetryTimeout);
        } else {
          event.target.complete();
        }
      }, err => {
        event.target.complete();
      });
  }


  getRestaurantDetails() {
    this.obj_restaurantDetails = this.kiosk_apiService.restaurant_Details()
      .subscribe((res) => {
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          if (this.shop.master_restaurant_status == 'M_ON') {
            this.is_maintaince_mode_on = true;
          }
          else {
            this.is_maintaince_mode_on = false;
          }
        }
      }, error => {
      });
  }

  getAppSettings() {
   this.kiosk_apiService.appsettings().subscribe(response => {
      if (response.status) {
        this.appSettings = response.aaData;
        this.settings = this.appSettings;
        this.app_maintanance_description2 = response.aaData.app_settings.app_maintanance_description ? response.aaData.app_settings.app_maintanance_description :  environment.M_ON;
        this.app_ella_maintanance_description2 = response.aaData.app_settings.app_ella_maintanance_description ? response.aaData.app_settings.app_ella_maintanance_description : environment.ELLA_M_ON;
        this.app_store_closed_description2 = response.aaData.app_settings.app_store_closed_description ? response.aaData.app_settings.app_store_closed_description : environment.STORE_CLOSED;
        this.kiosk_font_color= response.aaData.app_settings.app_store_kiosk_font_color;
        this.kiosk_banner_image=response.aaData.app_settings.app_store_banner_description_kiosk
      }
    }, err => {

    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  ionViewDidLeave() {
    clearInterval(this.for_api_timer);
    clearInterval(this.restaurant_status);
    clearInterval(this.time_left_counter);
    clearInterval(this.maintain_timer);
    clearInterval(this.time_left_redirection_counter);
    clearInterval( this.display_images)
    clearInterval(this.menuItems_timer)
    this.obj_is_restaurant_closed.unsubscribe();
    this.obj_restaurantDetails.unsubscribe();
  }

  switchOrderType(type) {
    this.orderType = type;
    this.store.set("order_type", type);
  }

  clock_Timer() {
    setInterval(function () {
      var dt = new Date();
      var ampm = dt.getHours() >= 12 ? 'PM' : 'AM';
      var clock_time = dt.getHours() + ":" + dt.getMinutes() + ":" + dt.getSeconds() + " " + ampm;
      if (clock_time === '7:0:0 AM' || clock_time === '7:30:0 PM' || clock_time === '19:30:0 PM') {
        window.location.reload();
      }
    });
  }

  go_to_maintenance() {
    location.href = "http://192.168.10.11:9001";
  }

  reset_time() {
    clearInterval(this.time_left_redirection_counter);
    this.page_redirection_time_left = page_redirection_time;
    this.time_left = cart_time;
    if (this.page_redirection_time_left = page_redirection_time) {
      this.time_left_redirection_counter = setInterval(() => {
        this.page_redirection_time_left -= 1;
        if (this.page_redirection_time_left == 0) {
          clearInterval(this.time_left_redirection_counter);
          this.store.setUserCart([]);
          this.store.remove("formFields");
          this.store.remove("cart");
          let Autologin = this.store.get('autoLoginUrl')
          this.store.remove('sessionId')
          this.store.hardlogout()
          location.href = DOMAIN_URL + Autologin;
          this.router.navigateByUrl('/scan');
          return false;
        }
      }, setIntervalTimeOut);
    }
  }

  logScrollStart(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  logScrolling(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  logScrollEnd(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
    this.time_left = cart_time;
  }

  payment_printing_setting() {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    let data = {
      "master_restaurant_uuid": master_restaurant_uuid
    }
    this.kiosk_apiService.app_settings()
      .subscribe(
        (res) => {
          this.res_settings = res.aaData.module_permissions
          this.one_pager_kiosk = this.res_settings.one_pager_kiosk
        },
        error => {
        }
      );
  }

}
