import { Component, OnInit } from '@angular/core';
import { LocalStoreService } from '../services/localstore.service';
import { environment } from 'src/environments/environment';
import { KioskApiService } from '../services/kiosk-api.service';
import { AuditLogService } from '../services/audit-log.service';
import { Router } from '@angular/router';
import { images_display_timer } from '../common/api';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.page.html',
  styleUrls: ['./scan.page.scss'],
})
export class ScanPage implements OnInit {
  is_maintaince_mode_on;
  appSettings: any;
  shop: any;
  settings = {};
  restaurant_status: any;
  is_restaurant_closed_status;
  maintain_mode;
  obj_restaurantDetails
  app_maintanance_description2;
  app_ella_maintanance_description2;
  app_store_closed_description2;
  obj_kiosksetting
  obj_restaurantstatus;
  store_info: any;
  screenImage = "";
  screenMode = "";
  kiosk_banner_image=''
  enable_for_kiosk ="";
  display_images:any;
  isEventAKiosk;
  showEllaSoldOutPopup = false;

  constructor(
    private router: Router,
    private store: LocalStoreService,
    private kiosk_apiservice: KioskApiService,
    private auditlogservice: AuditLogService) {
    this.getRestaurantDetails();
    this.is_restaurant_closed();
  }

  ngOnInit() {
    // this.getRestaurantDetails();
    // this.is_restaurant_closed();
    // this.getAppSettings();
    // this.display_images =setInterval(()=>{
    //   this.getAppSettings();
    // },images_display_timer)
    this.auditlogservice.logEvents("SCAN")
    this.auditlogservice.audit_log("SCAN")
  }

  ionViewWillEnter() {
    const localreaturent_uuid = localStorage.getItem("master_restaurant_uuid");
    this.isEventAKiosk = localreaturent_uuid == '781abb53841b9442497a4a01fd1f204a';
    this.store_info = this.store.get('restaurant');
    this.getAppSettings();
    this.getRestaurantDetails();
    this.is_restaurant_closed();
    this.maintain_mode = setInterval(() => {
      this.getRestaurantDetails();
    }, 10000)
    this.restaurant_status = setInterval(
      () => {
        this.is_restaurant_closed();
      }
      , 12000);
  }

  ;
  getRestaurantDetails() {

    this.obj_restaurantDetails = this.kiosk_apiservice.restaurant_Details()
      .subscribe((res) => {
        if (res.statusCode == 200) {
          localStorage.removeItem('restaurant');
          this.store.set('restaurant', res.aaData);
          this.store_info = res.aaData;
          this.scanScreenImage()
          this.enable_for_kiosk =  res.aaData.enable_kiosk
          // this.enable_for_kiosk =  "NO"
          this.store.getRestaurant() ? this.shop = this.store.getRestaurant() : this.shop = 'null';
          if (res?.aaData?.ella_orders_blocked && (res?.aaData?.ella_orders_blocked == 'true' || res?.aaData?.ella_orders_blocked == true)) {
            this.showEllaSoldOutPopup = true;
          } else {
            this.showEllaSoldOutPopup = false;
          }
          if (this.shop.master_restaurant_status == 'M_ON') {
            this.is_maintaince_mode_on = 'true';
          }
          else {
            this.is_maintaince_mode_on = 'false';
          }
        }
      }, error => {
      });
  }

  getAppSettings() {

    this.kiosk_apiservice.appsettings().subscribe(response => {

      if (response.status) {
        this.appSettings = response.aaData;
        this.settings = this.appSettings;

        this.app_maintanance_description2 = response.aaData.app_settings.app_maintanance_description ? response.aaData.app_settings.app_maintanance_description : environment.M_ON;
        this.app_ella_maintanance_description2 = response.aaData.app_settings.app_ella_maintanance_description ? response.aaData.app_settings.app_ella_maintanance_description : environment.ELLA_M_ON;
        this.app_store_closed_description2 = response.aaData.app_settings.app_store_closed_description ? response.aaData.app_settings.app_store_closed_description : environment.STORE_CLOSED;
        if(this.enable_for_kiosk == "YES"){
        this.kiosk_banner_image=response.aaData.app_settings.app_store_scan_description_kiosk
        // console.log(this.kiosk_banner_image)
      }
      else{
        this.kiosk_banner_image=response.aaData.app_settings.app_store_scan_description_kiosk
      }
      this.scanScreenImage()
      }
    }, err => { }
    );
  }

  go_to_maintenance() {
    location.href = "http://192.168.10.11:9001";
  }

  is_restaurant_closed() {
    let restaurantDetails = this.store.getRestaurant();
    let param = {
      "master_restaurant_uuid": restaurantDetails.master_restaurant_uuid
    }
    this.obj_restaurantstatus = this.kiosk_apiservice.restaurant_Operational()
      .subscribe(
        (res) => {
          if (res.statusCode == 200) {
            if (res.aaData == true) {
              this.is_restaurant_closed_status = "NO";
            } else {
              this.is_restaurant_closed_status = "YES";
            }
            this.scanScreenImage()
          }
        },
      );
  }

  ionViewDidLeave() {
    clearInterval(this.restaurant_status);
    clearInterval(this.maintain_mode)
    clearInterval( this.display_images)
    this.obj_restaurantstatus.unsubscribe();
    this.obj_restaurantDetails.unsubscribe();
  }

  scanScreenImage(){
    if(this.store_info.master_restaurant_status === 'M_ON'){
      this.screenMode = 'M_ON'
      this.screenImage =  this.app_maintanance_description2 
    }
    else if(this.store_info.master_restaurant_status === 'ELLA_M_ON'){
      this.screenMode = 'ELLA_M_ON'
      this.screenImage =  this.app_ella_maintanance_description2 
    }
    else if(this.store_info.master_restaurant_status === "ACTIVE" && this.is_restaurant_closed_status === "YES" ){
      this.screenMode = 'Store_close'
      this.screenImage =  this.app_store_closed_description2
    }
    else{
      this.screenMode = 'ACTIVE'
      this.screenImage = this.kiosk_banner_image
    }
  }

  redirectToHome(){
    this.router.navigate([`home`]);
  }
}
