import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuditLogService } from '../services/audit-log.service';
import * as moment from 'moment';

@Component({
  selector: 'app-kioskautologin',
  templateUrl: './kioskautologin.page.html',
  styleUrls: ['./kioskautologin.page.scss'],
})
export class KioskautologinPage implements OnInit {
  id:any;
  uuid:any;
  preloader = true;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Reference': 'Kiosk',
    })
  }

  constructor(
      private httpClient: HttpClient, 
      private router: Router,
      private kioskauthservice:KioskauthService,
      private route: ActivatedRoute,
      private store:LocalStoreService,
      private auditlogservice:AuditLogService
    ) { 
      this.id = this.route.snapshot.paramMap.get('id');
   
      this.uuid = this.route.snapshot.paramMap.get('uuid');
 
      let tid  =  this.route.snapshot.paramMap.get('tid');
      this.store.set('terminalid',tid);
      let payload = {"user_information_uuid": this.id, "user_hmac": this.uuid, "restaurant_type": "WCS"};  
      let kioskAutoLoginUrl = this.router.url;
      this.store.set('autoLoginUrl', kioskAutoLoginUrl);
      console.log('kioskAutoLoginUrl', kioskAutoLoginUrl)
      console.log('this.router.url', this.router.url)  
      this.kioskauthservice
      .apiReq('post', 'auth/elologin', payload)
      // .apiReq('post','auth/update',this.nickForm.value)
      .subscribe((response) => {
        if (response.status === false) {
          this.kioskauthservice.onError(response.message);
        } else {
          this.preloader = false;
          // this.utilityService.onSuccess(response.message);
          let data = response.aaData ;
          // console.log("  RES1: ", response);
          // console.log(data.master_restaurant_uuid);
          // console.log("-------------------------------")
          // console.log(data.user_information_uuid)
          // const sessionId = "123456"
          const sessionId = Math.random().toString(36).replace(/[^a-z]+/g, "").substr(0, 5) + moment().format('HHMMSS');
          this.store.set('sessionId', sessionId);
          localStorage.setItem("master_restaurant_uuid",data.master_restaurant_uuid);
          localStorage.setItem("user_information_uuid",data.user_information_uuid);
          let params = {
            master_restaurant_uuid: data.master_restaurant_uuid
          };  
          this.kioskauthservice
          .apiReq('post', 'masterrestaurant/getbyuuid', params)
          .subscribe((response) => {
            if(response.statusCode == 200) {
              this.store.set("user",data);
              this.store.set("restaurant",response.aaData);
              this.store.set("selectedCountry",data.country_detail);
              let language: any = {};
                  language['language_uuid'] = data.language.master_language_uuid;
                  language['language_code'] = data.language.master_language_code;
                  language['language_name'] = data.language.master_language_name;
              this.store.set("language",language);
              this.auditlogservice.logEvents("AUTO_LOGIN")
              this.auditlogservice.audit_log("AUTO_LOGIN")
              this.router.navigate(['/scan']);
            }
          })
          // this.router.navigate(['/tv-screen']); 
        }
      });
      // console.log("  payload: ",payload);
      // console.log("  IS LOGGED IN : ");
      // console.log( this.kioskauthservice.is_logged_in());
  }

  public  process_autologin(API_URL, payload) {
     
    this.httpClient.post(API_URL, payload, this.httpOptions)
    .subscribe(data => {          
          console.log("DATA: ", data);
     }, error => {
          console.log("ERROR: ",error);
    });     
  }

  ngOnInit() {
  }

}