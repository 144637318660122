import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { KioskauthService } from './services/kioskauth.service';

const routes: Routes = [
  {
    path: 'home',
    canActivate : [AuthGuard],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'scan',
    pathMatch: 'full'
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'product-detail',
    canActivate : [AuthGuard],
    loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'settlement-complete',
    canActivate : [AuthGuard],
    loadChildren: () => import('./settlement-complete/settlement-complete.module').then( m => m.SettlementCompletePageModule)
  },
  {
    path: 'kioskautologin/:id/:uuid/:tid',
    loadChildren: () => import('./kioskautologin/kioskautologin.module').then( m => m.KioskautologinPageModule),
    pathMatch: 'full'
  },  
  {
    path: 'autologin/1',
    loadChildren: () => import('./scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: 'scan',
    canActivate : [AuthGuard],
    loadChildren: () => import('./scan/scan.module').then( m => m.ScanPageModule)
  },
  {
    path: '**',
    redirectTo: 'scan'
 }

 



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  providers: [KioskauthService,AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
