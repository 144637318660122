import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DOMAIN_URL, page_redirection_time, setIntervalTimeOut } from '../common/api';
import { KioskApiService } from '../services/kiosk-api.service';
import { AuditLogService } from '../services/audit-log.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.page.html',
  styleUrls: ['./product-detail.page.scss'],
})
export class ProductDetailPage implements OnInit {
  params: any;
  master_menu_type: any;
  addOnPrice: any = {};
  addonsList = [];
  item: any = [];
  radioAddons = {};
  selected_addons = [];
  formFields: any = {};
  addons: Array<string> = [];
  quantity = 1;
  total: number;
  itemprice: number = 0;
  itembaseprice: number = 0;
  total_base_price: number;
  totalAddonPriceArray: any = [];
  totalQuantity: any = [];
  currency = "¥";
  showDecimal = true;
  bookmark_customization: any = {};
  requestFromCombo: boolean = false;
  totalvalue: number;
  obj_itemdetail;
  terminalid = this.store.get('terminalid');
  totalAmount
  cartItems_create_order: any = [];
  attempted;
  ServiceID
  balance
  deducted
  order_UUID
  isPaymentFailed
  app_settings
  proc_card_idi
  counter = 0
  page_redirection_time_left = page_redirection_time;
  kiosk_theme_color=""
  addons_select;
  addon_array =[];
  time_left_redirection_counter: any;

  constructor(
    private router: Router,
    private kioskauthservice: KioskauthService,
    private route: ActivatedRoute,
    private store: LocalStoreService,
    public alertController: AlertController,
    private kiosk_apiService: KioskApiService,
    private auditlogServices: AuditLogService
  ) {
    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
  }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.counter = 0
    this.app_setting()
    this.  getAppSettings()
    this.auditlogServices.logEvents("PRODUCT_DETAIL");
    this.auditlogServices.audit_log("PRODUCT_DETAIL")
    this.reset_time();
    this.radioAddons = [];
    this.store.remove('comboAddonPrice');
    this.store.set('formFieldsCombo', []);
    this.store.set('formFields', []);

    if (this.route.snapshot.queryParamMap.get('ci')) {
      let items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
      let cartIndex = this.route.snapshot.queryParamMap.get('ci');
      this.quantity = items[cartIndex].length;
      this.requestFromCombo = true;
    }
    this.store.remove('formFields')
    this.getProductDetail();
  }

  groupSectiontypeIsMultiple(inputType) {
    if (inputType == 'MULTIPLE') {
      return true;
    } else {
      return false;
    }
  }

  showAmountSection(amount) {
    if (amount == 0 || amount == 0.00) {
      return false;
    } else {
      return true;
    }
  }

  async getProductDetail() {
    this.obj_itemdetail = this.kiosk_apiService.item_detail()
      .subscribe(res => {
        if (res.aaData.is_food_category_time_available == false) {
          this.kioskauthservice.itemNotAvailableAlert();
          return false;
        }
        this.itemprice = 0;
        this.item = res.aaData;
        this.master_menu_type = res.aaData.master_menu_type;
        this.total = res.aaData.master_menu_item_price;
        this.total_base_price = Number(res.aaData.master_menu_item_price);
        this.itembaseprice = this.total;
        this.calculateTotal();
        if(this.item.bookmark_customization != ""){
          this.addons_select= JSON.parse(this.item.bookmark_customization);
          }
        this.get_prefilled_values(0);
      })
  }

  calculateTotal() {
    this.totalQuantity = [];
    for (let i = 1; i <= this.quantity; i++) {
      this.totalQuantity.push(i);
    }
    this.total = (+(this.quantity * this.itembaseprice)) + +this.itemprice;
  }

  changeAddonTotal(event, counter, addon) {
    const price = Number(document.getElementById(event.target.value)?.innerText?.slice(1,));
    if (event.target.checked) {

      this.radioAddons[event.target.name] = {
        name: event.target.value,
        price,
      };
      this.itemprice = (+this.itemprice) + (+price);
      this.calculateTotal();
    } else if (event.target.tagName === 'ION-RADIO-GROUP') {
      if (this.radioAddons[event.target.name]) {
        if (this.radioAddons[event.target.name].price != undefined) {
          this.itemprice = (+this.itemprice) - (+this.radioAddons[event.target.name].price);
        }
      }
      this.radioAddons[event.target.name] = {
        name: event.target.value,
        price,
      };
      this.itemprice = (+this.itemprice) + (+price);
      delete this.totalAddonPriceArray[counter + "_" + addon];
      this.totalAddonPriceArray[counter + "_" + addon] = this.itemprice;
      this.calculateTotal();
    } else {
      delete (this.radioAddons[event.target.name])
      this.addons.splice(this.addons.indexOf(event.target.value), 1);
      this.itemprice = (+this.itemprice) - (+price);
      this.calculateTotal();
    }
  }

  // async store_in_local(obj, counter) {
  //   if (counter > 0) {
  //     counter = counter - 1;
  //   }
  //   let master_addon_group_uuid = obj['master_addon_group_uuid'];
  //   if (obj.master_addon_group_selection_type == 'SINGLE') {
  //     if (this.bookmark_customization[master_addon_group_uuid]) {
  //       this.bookmark_customization[master_addon_group_uuid][0] = obj.master_addon_internal_name;
  //     }
  //   } else {
  //     let type_of_bookmark = typeof this.bookmark_customization[master_addon_group_uuid];
  //     if (type_of_bookmark == undefined || type_of_bookmark == 'undefined') {
  //       this.bookmark_customization[master_addon_group_uuid] = [];
  //     }
  //     let len = this.bookmark_customization[master_addon_group_uuid].length;
  //     if (obj.master_addon_name != null) {
  //       this.bookmark_customization[master_addon_group_uuid][len + 1] = obj.master_addon_internal_name;
  //     }
  //   }
  //   let addonsList = [];
  //   addonsList[counter] = this.bookmark_customization;
  //   let existingData = this.store.get('formFields');
  //   if (existingData != null) {
  //     let finalObj = Object.assign(existingData, addonsList);
  //     this.store.set('formFields', finalObj);
  //   } else {
  //     this.store.set('formFields', addonsList);
  //   }
  //   this.calculateTotal();
  // }
  async store_in_local(event,obj,counter) {
    if(counter > 0){
      counter = counter -1;
    }
    let master_addon_group_uuid = obj['master_addon_group_uuid'];
    if (obj.master_addon_group_selection_type == 'SINGLE') {
      if(this.bookmark_customization[master_addon_group_uuid]) {
        this.bookmark_customization[master_addon_group_uuid][0] = obj.master_addon_internal_name;
      }
    }
    else {
      let type_of_bookmark = typeof this.bookmark_customization[master_addon_group_uuid];
      if (type_of_bookmark == undefined || type_of_bookmark == 'undefined') {
        
        this.bookmark_customization[master_addon_group_uuid] = [];
      }
      
    }
   
    let addonsList = [];
 
  if(obj.master_addon_group_selection_type == "MULTIPLE"){
    if (event.target.checked) {
      if(obj.master_addon_name != null){
      delete  this.bookmark_customization[master_addon_group_uuid]
        
      }
   
    } else {
      let len = this.bookmark_customization[master_addon_group_uuid].length;
    if(obj.master_addon_name != null){
      this.bookmark_customization[master_addon_group_uuid][len] = obj.master_addon_internal_name;
    
    }
      addonsList[counter] = this.bookmark_customization;
    }
  } 

  addonsList[counter] = this.bookmark_customization;
  let existingData = this.store.get('formFields');
  if(existingData != null) {
    let finalObj = Object.assign(existingData, addonsList);
    this.store.set('formFields', finalObj);
  } else {
    this.store.set('formFields', addonsList);
   
  } 
  this.calculateTotal();
  }

  get_prefilled_values(counter) {  
    if(counter > 0){
      counter = counter -1;
    }
    this.bookmark_customization= Object.create( {} );
    for (var k in this.item.add_ons) {
      let rows = this.item.add_ons[k];     
      for (var a in rows) {
        if (rows.hasOwnProperty(a)) {
          let row = rows[a];
          this.addon_array = row[0];
          let master_addon_group_uuid = row[0].master_addon_group_uuid;
         if(JSON.stringify(this.addons_select) == "[]"){
          this.bookmark_customization[master_addon_group_uuid]=[];
         }else{
          this.bookmark_customization = this.addons_select
         }
        
          // if(master_addon_group_uuid == 'e19c4ec81d7161fea86eeb2e4f37cb0d'){
          //   this.bookmark_customization[master_addon_group_uuid] = [row[0].master_addon_internal_name]
          // } else if(master_addon_group_uuid == '774fb98d062c89309d9bca4f72be9361'){
          //   this.bookmark_customization[master_addon_group_uuid] = [row[0].master_addon_internal_name]
          // } else {
          //   this.bookmark_customization[master_addon_group_uuid]=[];
          // }
         }
      }      
    }
    let addonsList = [];
        addonsList[counter] = this.bookmark_customization;
    let existingData = this.store.get('formFields');
    if(existingData != null) {
      let finalObj = Object.assign(existingData, addonsList);
      this.store.set('formFields', finalObj);
    } else {
      this.store.set('formFields', addonsList);
    }
  }

  addToCart(item) {
    this.counter = 1
    let data1 = this.store.get('formFields');
    if (data1) {
      const items = this.store.isLoggedIn() ? this.store.getUserCart() : this.store.getCart();
      if (this.route.snapshot.queryParamMap.get('ci')) {
        let cartIndex = this.route.snapshot.queryParamMap.get('ci');
        items.splice(cartIndex, 1);
        this.store.setUserCart(items);
      }
      let data: any;
      let finalArray: any = [];
      for (var i = 0; i <= this.quantity; i++) {
        this.addons = [];
        if (data1[i] != null) {
          if (item.master_menu_item_is_combo == 'NO') {
            Object.entries(data1[i]).forEach(([k, v]) => {
              Object.entries(v).forEach(([k1, v1]) => {
                let addOnName: any = "";
                addOnName = v1;
                if (addOnName != null) {
                  this.addons.push(addOnName);
                }
              })
            })
          }

          if (item.master_menu_item_is_combo == 'YES') {
            Object.entries(this.radioAddons).forEach(([k, v]) => {
              if (v['name'] && v['name'] != null) {
                this.addons.push(v['name']);
              }
            });
          }
          let menu_item_total_price = item.master_menu_item_price;
          let addOnAmount: number = 0;
          Object.entries(this.totalAddonPriceArray).forEach(([kk, vv]) => {
            let arrayCounter: any = 0;
            let addOnPriceKey = kk.split("_");
            arrayCounter = addOnPriceKey[0];
            if (arrayCounter == i) {
              addOnAmount = +vv;
              menu_item_total_price = +vv + +menu_item_total_price;
            }
          })
          data = {
            id: this.route.snapshot.queryParamMap.get('id'),
            name: item.master_menu_item_name,
            thumb: item.master_menu_item_thumbnail ? item.master_menu_item_thumbnail[0].image : 'https://dev-ellaapplication.s3.us-east-2.amazonaws.com/ELLAAPPLICATION/coffee1.svg',
            type: item.master_menu_type,
            resturant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
            menu_item_total_price: this.total,
            item_addon_price: addOnAmount,
            menu_item_price: item.master_menu_item_price,
            total: this.total,
            total_base_price: this.total_base_price,
            quantity: 1,
            addons: this.addons,
            note: this.store.note ? this.store.note : null,
            food_type: item.master_menu_type,
            total_quantity: this.quantity,
          };
          if (items) {
            finalArray.push(data);
          } else {
            finalArray.push(data);
          }
        }
      }
      if (items) {
        items.push(finalArray);
        if (this.store.isLoggedIn()) {
          this.store.setUserCart(items);
        } else {
          this.store.setCart(items);
        }
      } else {
        if (this.store.isLoggedIn()) {
          this.store.setUserCart(finalArray);
        } else {
          this.store.setCart([data]);
        }
      }
    }
    setTimeout(() => {
      this.auditlogServices.logEvents("ADD_TO_CART")
      this.auditlogServices.audit_log("ADD_TO_CART")
    }, 200);

    setTimeout(() => {
      this.isItemCartAvaliable()
    }, 500);
    return false;
  }



  ionViewDidLeave() {
    this.counter = 0
    clearInterval(this.time_left_redirection_counter);
  }


 
  reset_time() {
    clearInterval(this.time_left_redirection_counter);
    this.page_redirection_time_left = page_redirection_time;
    if (this.page_redirection_time_left = page_redirection_time) {
      this.time_left_redirection_counter = setInterval(() => {
        this.page_redirection_time_left -= 1;
        if (this.page_redirection_time_left == 0) {
          clearInterval(this.time_left_redirection_counter);
          let Autologin = this.store.get('autoLoginUrl')
          this.store.remove('sessionId')
          this.store.hardlogout()
          location.href = DOMAIN_URL + Autologin;
          return false;
        }
      }, setIntervalTimeOut);
    }
  }

  logScrollStart(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
  }

  logScrolling(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
  }

  logScrollEnd(event) {
    clearInterval(this.time_left_redirection_counter);
    this.reset_time();
  }
  go_to_maintenance() {
    location.href = "http://192.168.10.11:9001";
  }

  //  payment ========>>>>>>>>


  app_setting() {
    this.kiosk_apiService.app_settings()
      .subscribe(
        (res) => {
          this.app_settings = res.aaData.module_permissions
        },
        error => {
        }
      );
  }

  isItemCartAvaliable() {
    this.kiosk_apiService.cart_available().subscribe(
      (res) => {
        if (res.statusCode == 200) {
          this.create_kiosk_order()
        }
        else {
          clearInterval(this.time_left_redirection_counter)
          this.soldItemModal(res)
        }
      }
    )
  }

  async soldItemModal(data) {
    this.store.setUserCart([]);
    let itemImage = data.message.item_image
    if (itemImage == undefined || itemImage == '') {
      itemImage = "assets/image/bell.svg"
    }
    else {

      itemImage = data.message.item_image
    }

    let message = data.message.error
    let html = `<div class="custom-head">
      <span><img src="`+ itemImage + `"></span>
      <h2>Sorry</h2>
      <p>`+ message + `</p>
      </div>`
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class default-modal',
      mode: 'md',
      message: html,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.counter = 0
            this.reset_time();
            this.alertController.dismiss();
          }
        }
      ]
    });
    setTimeout(() => {
      this.counter = 0
      this.alertController.dismiss();
      this.reset_time();
    }, 5000);
    await alert.present();
  }


  create_kiosk_order() {
    let cartDetail = this.store.getUserCart();
    this.totalAmount = 0;
    this.cartItems_create_order = [];
    Object.entries(cartDetail).forEach(([k, v]) => {
      Object.entries(v).forEach(([key, value]) => {
        if (v['total']) {
          this.totalAmount = v['total'] + +this.totalAmount;
        }
        this.cartItems_create_order.push(value);
      })
    });
console.log(cartDetail)
    let order_type = "TAKE_AWAY";
    if (this.store.get("order_type") == "Dine In") {
      order_type = "DINE_IN";
    }
    let totalCartAmount = this.totalAmount;
    let user = this.store.getUser();
    this.attempted = this.totalAmount;
    this.ServiceID = this.generate_rand_num();
    let data = {
      session_id: this.store.get('sessionId'),
      order_info_uuid: this.store.get('orderId'),
      order_info_customer: user.user_information_uuid,
      master_restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
      order_info_total_price: totalCartAmount,
      order_info_total_order_quantity: 0,
      order_info_total_discount: 0,
      available_redeemed_amount: 0,
      points_redeemed_amount: 0,
      master_coupon_uuid: null,
      master_subscription_uuid: null,
      subscription_mapping_uuid: null,
      used_subscription_quantity: 0,
      order_info_gst: 0, //Number(this.gst.toFixed(2)),
      // order_info_service_tax [OPTIONAL]
      order_info_grand_total: totalCartAmount,
      order_info_delivery_date: "",
      master_restaurant_type: this.store.getRestaurant().master_restaurant_type,
      orders: this.cartItems_create_order,
      order_info_table_number: null,
      order_info_car_plate_number: null,
      order_info_cutlery: 'FALSE',
      order_info_type: order_type,//this.store.get("order_type"),
      order_info_dinningmode: null,
      order_info_dinningtime: null,
      delivery_address: null,
      is_sap_order: "NO",
      country_uuid: this.store.getCountry().master_country_uuid,
      request_from: "EVENT_KIOSK",
      deducted: this.deducted,
      balance: this.balance,

    }
    this.auditlogServices.logEvents("PAYMENT", data, "CREATE_ORDER_PAYLOAD");
    this.auditlogServices.audit_log("PAYMENT", data, "CREATE_ORDER_PAYLOAD")
    this.kiosk_apiService.create_kiosk_order(data).subscribe((res) => {
      if (res.statusCode == 200) {
        this.auditlogServices.logEvents("PAYMENT", data, "ORDER_CREATED");
        this.auditlogServices.audit_log("PAYMENT", data, "ORDER_CREATED");

        this.updateOrder(res, null, "QUEUED");
        this.store.set("orderId", res.aaData['order_info_uuid']);
        this.store.remove("totalAmount");
        this.store.remove("totalCartAmount");
        this.store.setUserCart([]);
        this.store.remove("selectedItemsInCart");
        if (res.statusCode != 200) {
          if (res.aaData == false) {

          }
        }
        if (res.aaData['master_restaurant_status'] == 'M_ON') {
          this.router.navigate([`home`]);
        } else {
        }
      } else {
        this.counter = 0
        this.maintanenceOn(res);
      }
    }, err => {
      this.counter = 0
      this.technical_error(err)
    })

  }

  updateOrder(orderData, paymentResponse, isPaymentFailed) {
    this.order_UUID = orderData.aaData.order_info_uuid
    this.isPaymentFailed = isPaymentFailed
    let posted_data = {
      data: { payment_type: "ELLA", used_quantity: 0, order_info_uuid: this.order_UUID, payment_info_amount: 0, master_subscription_uuid: null, subscription_mapping_uuid: null }
      , payment_type: "NA", card_type: "OTHERS"
    }

    let data = {
      order_info_uuid: this.order_UUID,
      restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
      payment_response: paymentResponse,
      payment_status: isPaymentFailed ? isPaymentFailed : '',
      suica_payment_details: posted_data,
    }
    this.auditlogServices.logEvents("PAYMENT", data, "Update_order_payload");
    this.auditlogServices.audit_log("PAYMENT", data, "Update_order_payload");
    this.kiosk_apiService.update_order(data).subscribe(res => {
      this.auditlogServices.logEvents("PAYMENT", data, "Update_order_RES");
      this.auditlogServices.audit_log("PAYMENT", data, "Update_order_RES");
      this.store.remove("orderId");
      this.store.remove("totalAmount");
      this.store.remove("totalCartAmount");
      this.store.setCart([]);
      this.store.remove("selectedItemsInCart");
      if (this.isPaymentFailed == 'QUEUED' && this.app_settings.enable_kiosk_print) {
        this.router.navigateByUrl('/settlement-complete?id=' + this.order_UUID);
      }
      else if (!this.app_settings.enable_kiosk_print && this.isPaymentFailed == 'QUEUED') {
        this.counter = 0
        clearInterval(this.time_left_redirection_counter);
        const payment_cancel_timeout = setTimeout(() => {
          let Autologin = this.store.get('autoLoginUrl')
          let terminalid = this.store.get('terminalid')
          this.store.hardlogout()
          location.href = DOMAIN_URL + Autologin;
        }, 100)

      }
      return false;
    }, err => {
      this.counter = 0
      this.technical_error(err)
    }
    );
  }

  generate_rand_num() {
    var rand: any;
    rand = Math.random();
    rand = rand * 10000000000;
    rand = Math.floor(rand);
    rand = '' + rand;
    rand = rand.substr(0, 8);
    return rand;
  }

  async technical_error(err) {
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Oops!',//'Please Tap Again',
      message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
      buttons: [
        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            let Autologin = this.store.get('autoLoginUrl')
            this.store.remove('sessionId')
            this.store.hardlogout()
            location.href = DOMAIN_URL + Autologin;
          }
        }
      ]
    });
    setTimeout(() => {
      let Autologin = this.store.get('autoLoginUrl')
      this.store.remove('sessionId')
      this.store.hardlogout()
      location.href = DOMAIN_URL + Autologin;
      this.alertController.dismiss();
    }, 5000);
    (await alert).present();
  }

  async maintanenceOn(data) {
    this.store.setUserCart([]);

    let itemImage = "assets/image/bell.svg"

    let message = data.message
    let html = `<div class="custom-head">
      <span><img src="`+ itemImage + `"></span>
      <h2>Sorry</h2>
      <p>`+ message + `</p>
      </div>`
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class default-modal',
      mode: 'md',
      message: html,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            this.alertController.dismiss();
            this.reset_time();
          }
        }
      ]
    });
    setTimeout(() => {
      this.alertController.dismiss();
      this.reset_time();
    }, 5000);
    await alert.present();
  }

  myStyles(): any {
    
      return `--background:${this.kiosk_theme_color}`;
  }

  getAppSettings() {
    this.kiosk_apiService.appsettings().subscribe(response => {
       if (response.status) {
         this.kiosk_theme_color= response.aaData.app_settings.app_store_kiosk_font_color || "#D83289";
       }
     }, err => {
 
     });
   }
   
}

