import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { API_URL, apiSigningKey } from '../common/api';
import { API_URL2 } from '../common/api';
import { SUICA_SERVER } from '../common/api';
import { LOCAL_PAYMENT_API_URL } from '../common/api';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStoreService } from './localstore.service';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
declare const CryptoJSAesJson;

@Injectable({
  providedIn: 'root'
})
export class KioskauthService {
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Reference': 'Kiosk',
    })
  }

  constructor(
      private httpClient: HttpClient,
      private router: Router,
      private route: ActivatedRoute,
      private store:LocalStoreService,
      public alertController: AlertController,
    ) {
          
  }


  restaurant_Operational():Observable<any>{
    let restaurantDetails = this.store.getRestaurant();
    let param = {
    "ref": "kiosk",
    "master_restaurant_uuid": restaurantDetails.master_restaurant_uuid
    }
    return this.apiReq('post', 'masterrestaurant/isrestaurantoperational', param)
  }

  Menu_items():Observable<any>{
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid");
    let user_information_uuid = localStorage.getItem("user_information_uuid");
    let payload = { "ref": "kiosk", "master_restaurant_uuid": master_restaurant_uuid, "user_information_uuid": user_information_uuid };
// this.pageLoaderService.pageLoaderPersistent(true) //------------loader
    return this.apiReq('post', 'mastermenuitem/getcategorymenuitemsbyrestaurantuuid', payload)
  }

  restaurant_Details():Observable<any>{
    const payload ={
      "ref": "kiosk",
      'master_restaurant_uuid': localStorage.getItem("master_restaurant_uuid"),
    }
    return this.apiReq('post', 'masterrestaurant/getbyuuid', payload);
   }

   kiosk_settings():Observable<any>{
    let countryData = this.store.getCountry();
    let countryUuid = countryData['master_country_uuid'];
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    return this.apiReq('get', 'payment/kiosksetting?cid=' + countryUuid)
  }

   app_settings():Observable<any>{
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    let data = {
      "master_restaurant_uuid": master_restaurant_uuid
    }
    return this.apiReq('post', 'masterrestaurant/getrestaurantsetting', data)
   }

   create_kiosk_order(data):Observable<any>{
    return this.apiReq('post', 'orderinfo/createkioskorder', data)
  }


  update_order(data):Observable<any>{
    return this.apiReq('post', 'orderinfo/updatekioskorderstatus', data)
   }

   cart_available():Observable<any>{
    let restaurantDetails = this.store.getRestaurant();
    let orderitem = this.store.getUserCart();
    let data = {
      master_restaurant_uuid: restaurantDetails.master_restaurant_uuid,
      orders: orderitem[0]
    }
   return  this.apiReq('post', 'orderinfo/isitemavailable', data)
   }

   item_detail(){
    let item_uuid = this.route.snapshot.queryParamMap.get('id');
    let data = {
      "ref":"kiosk",
      master_menu_item_uuid: item_uuid,
      user_information_uuid: "",
      restaurant_uuid: this.store.getRestaurantuuid('master_restaurant_uuid'),
    };
    return this.apiReq('post', 'mastermenuitem/getitemdetailbyitemuuid', data)
  }



  hike_price(price){
    return  price+ ( price * 0.2);
  }

 
  is_logged_in(){
    let master_restaurant_uuid= localStorage.getItem("master_restaurant_uuid");
    let user_information_uuid= localStorage.getItem("user_information_uuid");

    if( master_restaurant_uuid !=null && master_restaurant_uuid !=undefined && user_information_uuid !=null && user_information_uuid !=undefined ){
        return true;
    }
    else{
        return false;
    }
  }  

  logout(){
      localStorage.clear();
      this.router.navigate(['/scan']);
  }

  apiReq(method: string, endPoint: string, payload?: any): Observable<any> {
    const signingKey = apiSigningKey;
    const timestamp = moment().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    const body = payload ? JSON.stringify(payload) : '';
    const data = `${timestamp}|${body}`;
    const signature = CryptoJS.HmacSHA256(data, signingKey).toString(CryptoJS.enc.Hex);
    let options: any = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        // 'token': '75FC9C20269896C09FB07E47F09AAF864AFC961F3C6FDEFB200F43E3876FA884',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Accept-Language': this.store.getLanguageUuid(),
        'Reference': 'Kiosk',
        'X-Signature': signature,
    'X-Timestamp': timestamp,
      })
  }
    switch (method) {
      case 'get':
        return this.httpClient.get(API_URL + endPoint).pipe(
          map(response => {
            if (typeof response == "string") {
              let password = apiSigningKey;
              const encryptedData = CryptoJSAesJson.decrypt(response, password);
              return encryptedData;
            } else {
              return response;
            }
          })
        );
      case 'post':
        return this.httpClient.post(API_URL + endPoint, payload,options).pipe(
          map(response => {
            if (typeof response == "string") {
              let password = apiSigningKey;
              const encryptedData = CryptoJSAesJson.decrypt(response, password);
              return encryptedData;
            } else {
              return response;
            }
          })
        );
      case 'put':
        return this.httpClient.put(API_URL + endPoint, payload).pipe(
          map(response => {
            if (typeof response == "string") {
              let password = apiSigningKey;
              const encryptedData = CryptoJSAesJson.decrypt(response, password);
              return encryptedData;
            } else {
              return response;
            }
          })
        );
      case 'patch':
        return this.httpClient.patch(API_URL + endPoint, payload).pipe(
          map(response => {
            if (typeof response == "string") {
              let password = apiSigningKey;
              const encryptedData = CryptoJSAesJson.decrypt(response, password);
              return encryptedData;
            } else {
              return response;
            }
          })
        );
      case 'delete':
        return this.httpClient.delete(API_URL + endPoint, payload).pipe(
          map(response => {
            if (typeof response == "string") {
              let password = apiSigningKey;
              const encryptedData = CryptoJSAesJson.decrypt(response, password);
              return encryptedData;
            } else {
              return response;
            }
          })
        );
      default:
        return null;
    }
  }

  paymentApiReq(method: string, endPoint: string, payload?: any): Observable<any> {
    let options: any = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type':  'application/json',
        // 'token': '75FC9C20269896C09FB07E47F09AAF864AFC961F3C6FDEFB200F43E3876FA884',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Accept-Language': this.store.getLanguageUuid(),
        'Reference': 'Kiosk',
      })
  }
    switch (method) {
      case 'get':
        return this.httpClient.get(LOCAL_PAYMENT_API_URL+ ':'+SUICA_SERVER + endPoint);
      case 'post':
        return this.httpClient.post(LOCAL_PAYMENT_API_URL+ ':'+SUICA_SERVER + endPoint, payload,options);
      case 'put':
        return this.httpClient.put(LOCAL_PAYMENT_API_URL+ ':'+SUICA_SERVER + endPoint, payload);
      case 'patch':
        return this.httpClient.patch(LOCAL_PAYMENT_API_URL+ ':'+SUICA_SERVER + endPoint, payload);
      case 'delete':
        return this.httpClient.delete(LOCAL_PAYMENT_API_URL+ ':'+SUICA_SERVER + endPoint, payload);
      default:
        return null;
    }
  }

  apiReq2(method: string, endPoint: string, payload?: any, options: any = this.httpOptions): Observable<any> {
    console.log({ method, endPoint, payload });
    switch (method) {
      case 'get':
        return this.httpClient.get(API_URL2 + endPoint);
      case 'post':
        return this.httpClient.post(API_URL2 + endPoint, payload,options);
      case 'put':
        return this.httpClient.put(API_URL2 + endPoint, payload);
      case 'patch':
        return this.httpClient.patch(API_URL2 + endPoint, payload);
      case 'delete':
        return this.httpClient.delete(API_URL2 + endPoint, payload);
      default:
        return null;
    }
  }

  onError(message): void {
   console.log("SERVICE ERROR: ", message);
  }

  async isRestaurantOperational(){
    let restaurantDetails = this.store.getRestaurant();
    let param = {
          "master_restaurant_uuid": restaurantDetails.master_restaurant_uuid
      }
    this.apiReq('post', 'masterrestaurant/isrestaurantoperational', param)
      .subscribe(
          (res) => {
              if (res.statusCode == 200) {
                if(res.aaData == true) {
                  return true;
                } else {
                  this.isRestaurantOpenAlert();
                  return false;
                }  
              } 
          },
      );
  }

  async isRestaurantOpenAlert(){
    let close_restaurant_title = "Sorry";
    let close_restaurant_message = "Outside business hours.";
    // this.translate.get('ERRORS').subscribe((data:any)=> {
    //     if(data.close_restaurant_title){
    //       close_restaurant_title         = data.close_restaurant_title;
    //     }
    //     if(data.close_restaurant_message){
    //       close_restaurant_message         = data.close_restaurant_message;
    //     }
    // });
    const alert = await this.alertController.create({
          backdropDismiss: false,
          cssClass: 'my-custom-class',
          mode: 'md',
          header: close_restaurant_title,
          message: close_restaurant_message,
          buttons: [       
            {
              text: 'Back',
              handler: () => {
                this.alertController.dismiss();
                this.router.navigateByUrl('/home');
              }
            }, {
              text: '',
              cssClass: 'cross',
              handler: () => {
                this.alertController.dismiss();
                this.router.navigateByUrl('/home');
              }
            }
          ]
        });
        await alert.present();
        return false;
  }

  async itemNotAvailableAlert(){
    let item_not_available_title = "Sorry!";
    let item_not_available_message = "Item not available till tomorrow.";
    // this.translate.get('ERRORS').subscribe((data:any)=> {
    //   if(data.itm_not_available_title) {
    //     item_not_available_title = data.itm_not_available_title;
    //   }
    //   if(data.itm_not_available_message) {
    //     item_not_available_message = data.itm_not_available_message;
    //   }
    // });
    const alert = await this.alertController.create({
      backdropDismiss: false,
      cssClass: 'my-custom-class',
      mode: 'md',
      header: item_not_available_title,
      message: item_not_available_message,
      buttons: [       
        {
          text: 'Back',
          handler: () => {
            this.alertController.dismiss();
            this.router.navigateByUrl('/home');
          }
        }, {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            this.router.navigateByUrl('/home');
          }
        }
      ]
    });
    await alert.present();
    return false;
  }
}
