import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { KioskauthService } from '../services/kioskauth.service';
import { LocalStoreService } from '../services/localstore.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateConfigService } from '../services/translate-config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { API_URL2, auto_logout_timer, DOMAIN_URL,PRINT_API_URL } from '../common/api';
import { PRINT_TIMEOUT,setIntervalTimeOut } from '../common/api';
import { ApiService } from '../services/api.service';
import { AuditLogService } from '../services/audit-log.service';


@Component({
  selector: 'app-settlement-complete',
  templateUrl: './settlement-complete.page.html',
  styleUrls: ['./settlement-complete.page.scss'],
})
export class SettlementCompletePage implements OnInit {
  orderInfo: any = [];
  paymentResponse: any = [];
  currency = "¥";
  showDecimal = true;
  print_url;
  render_order_number: any = "";
  order_info_uuid: string;
  card_idi: string;
  bal: string;
  QR_order_info_uuid: string;
  printUrl: string;
  time_left_counter: any;
  time_left = 0;
  order_number;
  ORDER_UUID;
  obj_orderDetais
  obj_qrcode;
  app_settings

  constructor(
    private api: ApiService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private kioskauthservice: KioskauthService,
    private route: ActivatedRoute,
    private store: LocalStoreService,
    public alertController: AlertController,
    private translateConfigService: TranslateConfigService,
    private auditlogservices: AuditLogService
  ) {
    this.translateConfigService.getDefaultLanguage();

    this.showDecimal = true;
    if (this.store.getCountry()) {
      let countryData = this.store.getCountry();
      this.currency = countryData['master_country_currency_code'];
      if (countryData['master_country_currency_decimal_places'] == 0) {
        this.showDecimal = false;
      }
    }
    this.ORDER_UUID = this.route.snapshot.queryParamMap.get('id');
    this.getQRCode();
  }

  ngOnInit() {
    this.auditlogservices.logEvents("SETTLEMENT")
    this.auditlogservices.audit_log("SETTLEMENT")
    this.time_left = auto_logout_timer;
    this.time_left_counter = setInterval(() => {
      this.time_left -= 1;
      if (this.time_left == 0) {
        clearInterval(this.time_left_counter);
        this.store.setUserCart([]);
        this.store.remove("formFields");
        this.store.remove("cart");
        this.alertController.dismiss();
        let Autologin = this.store.get('autoLoginUrl')
        let terminalid = this.store.get('terminalid')
        this.store.hardlogout()
        location.href = DOMAIN_URL + Autologin;
      }
    }, setIntervalTimeOut);

  }

  getQRCode() {
    this.ORDER_UUID = this.route.snapshot.queryParamMap.get('id');
    this.order_info_uuid = localStorage.getItem('ellaOrderId');
    this.QR_order_info_uuid = localStorage.getItem('ellaOrderId') + '.';
    if (this.route.snapshot.queryParamMap.get('id')) {
      this.order_info_uuid = this.route.snapshot.queryParamMap.get('id');
      this.card_idi = this.route.snapshot.queryParamMap.get('c');
      this.bal = this.route.snapshot.queryParamMap.get('b');
      this.QR_order_info_uuid = this.route.snapshot.queryParamMap.get('id') + '.';
    }
    this.obj_qrcode = this.kioskauthservice.apiReq('post', 'orderinfo/getbyuuid', { order_info_uuid: this.ORDER_UUID })
      .subscribe(res => {
        this.order_number = res?.aaData?.order_number
      });
    this.getOrderDetails();
  }

  getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  auto_print() {
    let purl = API_URL2 + "restapi/newinvoice?oid=" +this.order_number;
    console.log("Calling Print API :-", PRINT_API_URL + purl);
    this.printUrl = PRINT_API_URL + purl;
    this.api.printPdf(PRINT_API_URL + purl)
      .subscribe(res => {
        console.log("Print RESPONSE :-", res);
      },
        error => {
          console.log("Print Error:-", error);
        })
    setTimeout(
      () => {
        this.store.setUserCart([]);
        this.store.setUserCart([]);
        this.store.remove("formFields");
        this.store.remove("cart");
        this.alertController.dismiss();
      }
      , PRINT_TIMEOUT);
  }

  getOrderDetails() {
    let order_info_uuid = this.route.snapshot.queryParamMap.get('id');
    let data = {
      order_info_uuid: order_info_uuid
    };
    console.log("ID: " + this.route.snapshot.queryParamMap.get('id'))
    this.obj_orderDetais = this.kioskauthservice.apiReq('post', 'orderinfo/getbyuuid', data)
      .subscribe(res => {
        this.orderInfo = res.aaData;
        console.log(" ===> " + this.orderInfo?.order_number)
        let render_order_number1 = this.orderInfo?.order_number.split("_");
        if (render_order_number1[1] != null && render_order_number1[1] != undefined) {
          let render_order_number = render_order_number1[1];
          this.render_order_number = render_order_number;
        }
        this.order_number = this.orderInfo?.order_number
        this.auto_print()
        console.log("ORDER NUMBER: " + this.render_order_number)
      },
        error => {
          console.log(error)
        })
  }

  goBack() {
    this.store.setUserCart([]);
    this.router.navigate([`home`]);
  }

  check_balance() {
    this.api.balance_check().subscribe(
      (r) => {
        if (r['balance']) {
          this.paymentResponse = r;
        }
      }
    );
  }

  callPaymentApi(orderUuid) {
    let res: any = [];
    this.updateOrderStatus(orderUuid, res);
  }

  updateOrderStatus(orderUuid, res) {
    let data = {
      order_info_uuid: orderUuid,
      restaurant_uuid: this.store.getRestaurantuuid("master_restaurant_uuid"),
      payment_response: res
    }
    this.kioskauthservice.apiReq('post', 'orderinfo/updatekioskorderstatus', data).subscribe(res => {
      if (res.statusCode == 200) {
        this.store.remove("orderId");
        this.store.remove("totalAmount");
        this.store.remove("totalCartAmount");
        this.store.setCart([]);
        this.store.remove("selectedItemsInCart");
        this.router.navigate([`settlement-complete`]);
      }
      if (res.statusCode != 200) {
        if (res.aaData == false) {
          this.technical_error()
        }
      }
    },
      error => {
        this.technical_error
        console.log(error)
      }
    )
  }


  go_to_maintenance() {
    location.href = "http://192.168.10.11:9001/maintenance";
  }

  new_order() {
    clearInterval(this.time_left_counter);
    console.log("  *************** LOGIN LOGOUT STARTED ******************");
    let Autologin = this.store.get('autoLoginUrl')
    let terminalid = this.store.get('terminalid')

    this.store.hardlogout()

    location.href = DOMAIN_URL + Autologin;
  }

  ionViewDidLeave() {
    this.obj_orderDetais.unsubscribe();
    this.obj_qrcode.unsubscribe();
    clearInterval(this.time_left_counter);
  }


  async technical_error() {
    const alert = this.alertController.create({
      cssClass: 'my-custom-class',
      mode: 'md',
      header: 'Oops!',
      message: '<h2>Looks like we hit a snag! You will be redirected back in 5 seconds</h2>',
      buttons: [

        {
          text: '',
          cssClass: 'cross',
          handler: () => {
            this.alertController.dismiss();
            this.router.navigateByUrl('/Scan');
          }
        }
      ]
    });
    setTimeout(() => { this.router.navigateByUrl('/Scan'), this.alertController.dismiss(); }, 5000);
    (await alert).present();
  }


  app_setting() {
    let master_restaurant_uuid = localStorage.getItem("master_restaurant_uuid")
    let data = {
      "master_restaurant_uuid": master_restaurant_uuid
    }
    this.kioskauthservice.apiReq('post', 'masterrestaurant/getrestaurantsetting', data)
      .subscribe(
        (res) => {
          this.app_settings = res.aaData.module_permissions

        },
        error => {
        }
      );

  }

}
